import Api from '@/core/services/_api.config';
import LegacyApi from '@/core/services/_legacy-api.config';

async function getProduct(upc, getChildren, _TargetLayerID) {
    const targetLayerParam = _TargetLayerID ? `&targetLayerID=${_TargetLayerID}` : '';
    return await Api()
                .get(`/api/Product/Upc/${upc}?getChildren=${getChildren}${targetLayerParam}`)
                .then(response => {
                    return ({ status: response.status, data: response.data });
                })
                .catch((e) => {
                    if (e.response) {
                        return { status: e.response.status, message: e.response.data, data: null };
                    }
                    else {
                        return { message: e.message, data: null };
                    }
                });
}

async function callProductGetEndPoints(endPointName, { getChildren, filters, search, _ReportDataModelID, _TargetLayerID }) {
    const searchParams = new URLSearchParams()
    searchParams.append('getChildren', getChildren.toString())
    
    searchParams.append('filters', encodeURIComponent(JSON.stringify( filters?filters:[])))

    if(search){
        searchParams.append('search', encodeURIComponent(JSON.stringify(search)))
    }

    if(_ReportDataModelID){
        searchParams.append('_ReportDataModelID', _ReportDataModelID.toString())
    }

    if(_TargetLayerID){
        searchParams.append('targetLayerID', _TargetLayerID.toString())
    }

    return await Api()
                .get(`/api/Product/${endPointName}?${searchParams.toString()}`)
                .then(response => {
                    return ({ status: response.status, data: response.data });
                })
                .catch((e) => {
                    if (e.response) {
                        return { status: e.response.status, message: e.response.data, data: null };
                    }
                    else {
                        return { message: e.message, data: null };
                    }
                });
}

async function getProducts({ getChildren, filters, search, _ReportDataModelID, _TargetLayerID }) {
    return await callProductGetEndPoints('', { getChildren, filters, search, _ReportDataModelID, _TargetLayerID })
}

async function getProductsWithCosts({ getChildren, filters, search, _ReportDataModelID, _TargetLayerID }) {
    return await callProductGetEndPoints('GetProductsWithCosts', { getChildren, filters, search, _ReportDataModelID, _TargetLayerID })
}

async function getProductSales(upcs, filters) {
    const searchParams = {
        upcs: upcs,
        filters: encodeURIComponent(JSON.stringify(filters ? filters : []))
    }

    return await Api()
        .post(`/api/Product/GetProductsSales`, searchParams)
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

async function getSingleCostByCode(filters) {
    return await Api()
                .get(`/api/ProductCost?filters=${encodeURIComponent(JSON.stringify(filters))}`)
                .then(response => {
                    return ({ status: response.status, data: response.data });
                })
                .catch((e) => {
                    if (e.response) {
                        return { status: e.response.status, message: e.response.data, data: null };
                    }
                    else {
                        return { message: e.message, data: null };
                    }
                });
    }

async function getCosts({ getChildren, _ReportDataModelID, filters, targetLayerID }) {
    const reportDataModelParam = _ReportDataModelID != null ? `/${_ReportDataModelID}` : '';
    const targetLayerIDParam = targetLayerID != null ? `&targetLayerID=${targetLayerID}` : '';
    return await Api()
        .get(`/api/ProductCost/List/${getChildren}${reportDataModelParam}?filters=${encodeURIComponent(JSON.stringify(filters))}${targetLayerIDParam}`)
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

async function getUom(id, getChildren, _TargetLayerID) {
    const targetLayerParam = _TargetLayerID ? `&targetLayerID=${_TargetLayerID}` : '';
    return await Api()
                .get(`/api/UOM/${id}?getChildren=${getChildren}${targetLayerParam}`)
                .then(response => {
                    return ({ status: response.status, data: response.data });
                })
                .catch((e) => {
                    if (e.response) {
                        return { status: e.response.status, message: e.response.data, data: null };
                    }
                    else {
                        return { message: e.message, data: null };
                    }
                });
    }

async function updateItemsForSale(itemUpcs){
        let response;
        try{
            response = await LegacyApi().post('/api/Inventory/UpdateItemsForSaleState', itemUpcs)
        }
        catch(e) {
            return { status: e?.response?.status, message: e?.response?.data, data: null };
        }
        
        return { status: response.status, data: response.data }
    }

export const itemClient = {
    getProduct,
    getProducts,
    getProductSales,
    getProductsWithCosts,
    getSingleCostByCode,
    getCosts,
    getUom,
    updateItemsForSale
};
