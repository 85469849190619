import { stringFunction } from '@/core/functions'

export const GridId = {
    Clients: 'Clients',
    Order: 'Order',
    OrderReceiving: 'OrderReceiving',
    Orders: 'Orders',
    Promotion: 'Promotion',
    Promotions: 'Promotions',
    Inventories: 'Inventories',
    Inventory: 'Inventory',
    DirectReceivings: 'DirectReceivings',
    DirectReceivingItems: 'DirectReceivingItems',
    LabelBatch: 'LabelBatch',
    PrintBatch: 'PrintBatch',
    PriceLevels: 'PriceLevels',
    TaxExemptions: 'TaxExemptions'
}

export const AgToolPanels = {
    Columns: 'columns',
    Filters: 'filters'
}

export const ToolPanels = {
    Options: {
        id: 'Options',
        labelKey: 'options',
        iconKey: 'options',
        toolPanel: 'optionsToolPanel'
    }
}

export const Icons = {
    options: '<i class="fa-light fa-gear"/>'
}

export const AgFilter = {
    DateColumnFilter: 'agDateColumnFilter',
    MultiColumnFilter: 'agMultiColumnFilter',
    NumberColumnFilter: 'agNumberColumnFilter',
    SetColumnFilter: 'agSetColumnFilter',
    TextColumnFilter: 'agTextColumnFilter'
}

export const AgFilterType = {
    Set: 'Set'
}

export const AgAllowedAggFuncs = {
    Text: ['first', 'last']
}

export const MultiFilterParams = {
    NormalizeTextFilters: { 
        filters: [
            {
                filter: 'agTextColumnFilter',
                filterParams: {
                    textFormatter: stringFunction.normalize
                } 
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    textFormatter: stringFunction.normalize
                } 
            }
        ]
    },
    NumberFilters: {
        filters: [
            {
                filter: AgFilter.NumberColumnFilter,
                filterParams: {
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.toString().replace(',', '.'))
                    }
                }
            },
            {
                filter: AgFilter.SetColumnFilter
            }
        ],
        allowedCharPattern: '\\d\\-\\,\\.'
    }
}