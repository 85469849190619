import Api from '@/core/services/_api.config';

const orderApiRoute = '/api/Purchase/Order';

async function getOrder(id) {
    const searchCriteria = encodeURIComponent(JSON.stringify({ orderId: id }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

async function getOrders(){
    try
    {
        const response = await Api().get(`${orderApiRoute}`);
        return { status: response.status, data: response.data } ;
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

async function getOrdersByVendorName(vendorName){
    const searchCriteria = encodeURIComponent(JSON.stringify({ vendorName: vendorName }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

async function getOrdersByNo(orderNo){
    const searchCriteria = encodeURIComponent(JSON.stringify({ orderNo: orderNo }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

async function getOrdersByIds(ids){
    const searchCriteria = encodeURIComponent(JSON.stringify({ orderIds: ids }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e.message, data: null };
    }
}


async function getOrdersByVendorCode(vendorCode) {
    const searchCriteria = encodeURIComponent(JSON.stringify({ vendorCode: vendorCode }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e.message, data: null };
    }
}

async function addOrders(order) {
    try
    {
        const response = await Api().post(orderApiRoute, order);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null } ;
    }
}

async function updateOrders(order) {
    try
    {
        const response = await Api().put(orderApiRoute, order);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

async function sendOrderByEmailToVendor(payload) {
    try
    {
        const response = await Api().post(`${orderApiRoute}/Send`, payload);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

export const orderServices = {
    getOrder,
    getOrders,
    getOrdersByVendorName,
    getOrdersByNo,
    getOrdersByIds,    
    getOrdersByVendorCode,
    addOrders,
    updateOrders,
    sendOrderByEmailToVendor
};
