<template src="./promotion-product-search.html"></template>

<script>
import { mapGetters, mapMutations} from 'vuex';
import promotionProductScan from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-scan/promotion-product-scan.vue';
import prompts from '@/core/tools/notifications/notifications';
import { promotionClient } from '@/backoffice/modules/promotion/services/promotion.client.js'
import ProductSearch from '@/core/components/layout/side-panel/product-search/product-search.vue'
import SearchError from '@/backoffice/modules/purchase/components/sidePanel/orders/scan-search-error/error.vue'
 
export default {
    name: 'promotion-product-search',

    components: {
        ProductSearch,
        SearchError
    },
    computed:{
        ...mapGetters('Promotion', ['getPromotionItems', 'getPromotionDiscount']),
        canSave(){
            return this.itemsTosave.length > 0 && !this.saving
        },
        itemsTosave(){
            return this.selectedItems.map(item => {
                return {
                    discountId: this.discount?.id,
                    upc: item.itemUPC
                }
            }).filter(upc => !this.itemExist(upc))
        },
        discount(){
            return this.getPromotionDiscount
        },
        existingItems(){
            return this.getPromotionItems.map(elt => { return { itemUPC: elt.upc }})
        }
    },

    data() {
        return {
            invalid: false,
            selectedItems:[],
            saving: false
        }
    },

    methods: {
        ...mapMutations("Promotion", {addPromotionItems: 'ADD_PROMOTION_ITEMS'}),
        cancel(){
            this.$sidePanel.show([{ component: promotionProductScan }], null, { backgroundIsDisabled: true })
        },
        itemExist(item){
            let index = -1;
            if(this.discount?.items?.length > 0){
                index = this.discount.items.findIndex(i => i.upc === item.upc)
            }
            return index != -1;
        },
        closeError() {
            this.invalid = false;
        },
        handleSuccessfulSavePrompt(closeSidePanel) {
            if(closeSidePanel){
                this.$sidePanel.close()
            }
        },

        selectedElementChanged(selectedElements){
            this.selectedItems = []
            if(Array.isArray(selectedElements)){
                this.selectedItems = [...selectedElements]
            }
        },
        async save(closeSidePanel=false){
            this.saving = true;

            const sizeToSave = this.itemsTosave.length
            if(sizeToSave > 0){
                let savedItems = []
                try{
                    savedItems = await promotionClient.addPromotionItems(this.itemsTosave)
                    this.selectedItems = []
                    this.addPromotionItems(savedItems)
                }
                catch(error){
                    prompts.error({text: 'error'})
                    return
                }
                

                let text = ''
                if(savedItems.length !== sizeToSave){
                    text+= this.$t('Promotion.Messages.AtLeastOneItemNotIncluded')
                }
                text += `${savedItems.length}/${sizeToSave} ${this.$t('Promotion.Messages.AddItemToPromotionOk')}`
                prompts.info({text}).then(this.handleSuccessfulSavePrompt(closeSidePanel))
                                
            }
            else{
                prompts.info({text: this.$t('Promotion.Messages.NoPromotionItemToSave')})
            }
            this.saving = false;
        },
        async addItemToPromotionDiscount(){
            await this.save(true)
        },
        async addCurrentItemToPromotionDiscount(){
            await this.save()
        }
    }
}
</script>