import { orderServices } from '@/backoffice/modules/purchase/services/order/order.services';
import { receivingServices } from '@/backoffice/modules/purchase/services/receiving/receiving.services';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import { getCurrentLanguageDescription } from '@/core/functions/descriptions-helper.js';
import { ReceivingGridTabs } from '@/backoffice/modules/purchase/pages/direct-receivings/direct-receiving/receivingGridTabs.js';

const getDefaultState = () => {
  return {
    aggregateReceivings:[],
    receiving: null,
    relatedOrders: [],
    relatedReceivings: [],
    receivingVarianceReasons: [],
    closeReceivingStatus: true,
    directReceivings: [],
    receivingGridTabIndex: ReceivingGridTabs.ReceivingBodiesTabIndex
  }
}

export const Receiving = {
  namespaced: true,
  name: 'Receiving',
  state: getDefaultState(),
  getters: {
    getAggregateReceivings: state =>{
      return state.aggregateReceivings
    },

    getDirectReceivings: state => {
      return state.directReceivings;
    },

    getReceiving: state => {
      return state.receiving;
    },

    getRelatedOrders: state => {
      return state.relatedOrders;
    },
    
    getReceivingRelatedInfos: state => {
      const orderByIds = state.relatedOrders.reduce((agg, n) => {
        agg[n.id] = n;
        return agg;
      }, {});

      const orderBodies = {}

      const allOrderBodies = state.relatedOrders.flatMap(r => r.orderBodies);
      for (const body of allOrderBodies) {

        orderBodies[body.id] = {
          orderId : body.orderID,
          orderNumber: orderByIds[body.orderID].orderNO,
          qtyOrdered: body.qty,
          unitCostOrdered: body.unitCost
        }
      }

      for(const receivingBody of state.relatedReceivings) {

        const orderBody = orderBodies[receivingBody.orderBodyID];
        if (orderBody) {
          orderBody.qtyReceivedInOtherReceiving = (orderBody.qtyReceivedInOtherReceiving || 0) + receivingBody.qty
        }
      }

      return orderBodies;
    },

    getRelatedReceivings: state => {
      return state.relatedReceivings;
    },

    getReceivingVarianceReasons: state => {
      return state.receivingVarianceReasons;
    },

    getCloseReceivingStatus: state=>{
      return state.closeReceivingStatus;
    },

    getReceivingGridTabIndex: state => {
      return state.receivingGridTabIndex;
    },
  },

  mutations: {
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },
    
    SET_AGGREGATE_RECEIVINGS(state, aggregateReceivings){
      state.aggregateReceivings = aggregateReceivings;
    },
    SET_RELATED_ORDERS(state, relatedOrders) {
      state.relatedOrders = relatedOrders;
    },
    SET_RELATED_RECEIVINGS(state, relatedReceivings) {
      state.relatedReceivings = relatedReceivings;
    },
    SET_RECEIVING(state, receiving) {
      state.receiving = receiving;
    },
    SET_RECEIVING_VARIANCE_REASONS(state, receivingVarianceReasons){
      state.receivingVarianceReasons = receivingVarianceReasons;
    },
    RESET_AGGREGATE_RECEIVINGS(state){
      state.aggregateReceivings = []
    },
    RESET_RELATED_RECEIVINGS(state){
      state.relatedReceivings = []
    },
    RESET_RECEIVING(state){
      state.receiving = null
    },
    RESET_RELATED_ORDERS(state){
      state.relatedOrders = []
    },
    SET_CLOSE_RECEIVING_STATUS(state, status){
      state.closeReceivingStatus = status
    },
    SET_DIRECT_RECEIVINGS(state, directReceivings){
      state.directReceivings = directReceivings
    },
    RESET_DIRECT_RECEIVINGS(state){
      state.directReceivings = []
    },
    SET_RECEIVING_GRID_TAB_INDEX(state, receivingGridTabIndex) {
      state.receivingGridTabIndex = receivingGridTabIndex;
    },
  },

  actions: {
    async fetchAggregateReceivings({ commit }) {
      const response = await receivingServices.getAggregateReceivings();

      if(response.status == 200) {
        commit('SET_AGGREGATE_RECEIVINGS', response.data)
      }
      else {
        commit('SET_AGGREGATE_RECEIVINGS', []);
        throw response;
      }
    },

    async fetchDirectReceivings({ commit }) {
      const response = await receivingServices.getDirectReceivings();

      if(response.status == 200) {
        commit('SET_DIRECT_RECEIVINGS', response.data)
      }
      else {
        commit('SET_DIRECT_RECEIVINGS', [])
        throw response;
      }
    },

    async fetchReceiving({ commit }, id) {
      const response = await receivingServices.getReceiving(id);

      if (response.status == 200) {
        commit('SET_RECEIVING', response.data);
      }
      else {
        commit('SET_RECEIVING', null);
        throw response;
      }
    },
    async fetchRelatedOrders({ commit }, receiving) {

      const orderIds = [...new Set((receiving.receivingBodies || []).map(b => b.orderID))];
      if (!orderIds.length) {
        commit('SET_RELATED_ORDERS', []);
        return;
      }

      const response = await orderServices.getOrdersByIds(orderIds);

      if (response.status == 200) {
        commit('SET_RELATED_ORDERS', response.data)
      }
      else {
        commit('SET_RELATED_ORDERS', []);
        throw response;
      }
    },

    async fetchRelatedReceivings({ commit }, { receivingId: receivingId, orderIds: orderIds }) {

      if (!orderIds.length) {
        commit('SET_RELATED_RECEIVINGS', []);
        return;
      }

      const params = {
        OrderIds: orderIds,
        receivingStatus: ReceivingStatus.CLOSED
      };

      if(receivingId){
        params["receivingID"] = receivingId;
      }

      const response = await receivingServices.getReceivings(params);
  
      if(response.status == 200) {
        commit('SET_RELATED_RECEIVINGS', response.data)
      }
      else {
        commit('SET_RELATED_RECEIVINGS', []);
        throw response;
      }
    },
    //Receiving actions
    async saveReceiving({ commit }, receiving) {
      let response = null;

      if (!receiving.id) {
        response = await receivingServices.addReceiving(receiving);
      }
      else {
        response = await receivingServices.updateReceiving(receiving);
      }
  
      if (response.status == 200) {
        commit('SET_RECEIVING', response.data);
      }
      else {
        throw response;
      }
    },

    async fetchReceivingVarianceReasons({ commit }){
      const response = await receivingServices.getReceivingVarianceReasons();
      if(response.status == 200) {
        const reasons = response.data.map(reason => {
          const description = getCurrentLanguageDescription(reason.descriptions)
          return {
            ...reason,
            description: description?.value
          }
        })

        commit('SET_RECEIVING_VARIANCE_REASONS', reasons);
      }
      else {
        commit('SET_RECEIVING_VARIANCE_REASONS', []);
        throw response;
      }
    },
    async closeReceiving({ commit }, params) {
      let response = null;

      response = await receivingServices.closeReceiving(params);
  
      if (response.status == 200) {
        commit('SET_RECEIVING', response.data);
        commit('SET_CLOSE_RECEIVING_STATUS', true)
      }
      else {
        commit('SET_CLOSE_RECEIVING_STATUS', false)
        throw response;
      }
    },
    async closeDirectReceiving({ commit }, receivingId) {
      let response = null;

      response = await receivingServices.closeDirectReceiving(receivingId);
  
      if (response.status == 200) {
        commit('SET_RECEIVING', response.data);
      }
      else {
        throw response;
      }
    },
  }
}
