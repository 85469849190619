<template src="./purchase-vendor-products.html"></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Operators } from '@/core/components/common/backend-filters/operators/operators.js';
import grid from '@/core/components/common/grid/grid.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export default {
    name: 'purchaseVendorProducts',
    props: {
        vendorCode: String,
        itemUpcs: Array,
        selectedColumnLabel: {
            type: String,
            default: () => {
                return 'General.Selected'
            }
        }
    },
    components: {
        grid,
        searchBar
    },
    data() {
        return {
            gridOptions: {
                api: null,
                columnApi: null,
                headerHeight: 50,
                rowHeight: 50,
                defaultColDef: {
                    resizable: true,
                    sortable: true
                },
                rowSelection: 'single',
                getRowId: (params) => params.data?.itemUPC,
                onGridReady: () => {
                    this.loadData();
                }
            },
            filters: [],
            vendorProducts: null,
            productsInventories: {},
            productsAvgLast4Weeks: {}
        }
    },
    computed:{        
        ...mapGetters('App', ['isMobile']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
        columnDefs(){ 
            const cellRenderer = this.isMobile ? 'dropdownRenderer' : 'clickCallbackRenderer';
            const cellRendererParams = this.isMobile ? {
                openSidePanel: (row) => this.$emit('onProductClick', row.itemUPC),
                canDelete: false
            } : { callback: (row) => this.$emit('onProductClick', row.itemUPC) }
            
            return [
                { hide: this.isMobile, field: 'selected', colId: 'selected', headerName: this.$t(this.selectedColumnLabel), headerTooltip: this.$t(this.selectedColumnLabel), cellRenderer: 'booleanRenderer', valueGetter: (params) => params?.data?.selected ? params.data.selected.toString() : 'false' },
                { field: 'description', headerName: this.$t('Product.Columns.Description'), headerTooltip: this.$t('Product.Columns.Description'), cellRenderer: cellRenderer, cellRendererParams: cellRendererParams, cellClass: this.isMobile ? "dropdown-cellrenderer" : "" },
                { hide: this.isMobile, field: 'itemUPC', sort: '', headerName: this.$t('Product.Columns.ItemUPC'), headerTooltip: this.$t('Product.Columns.ItemUPC') },
                { hide: this.isMobile, field: 'departmentCode', sort: '', headerName: this.$t('Product.Columns.DepartmentCode'), headerTooltip: this.$t('Product.Columns.DepartmentCode') },
                { hide: this.isMobile, field: 'sdpCode', sort: '', headerName: this.$t('Product.Columns.SdpCode'), headerTooltip: this.$t('Product.Columns.SdpCode') },
                { hide: this.isMobile, colId: 'avgLast4Weeks', headerName: this.$t('Inventory.InventoryProduct.AverageLastFourWeeks'), headerTooltip: this.$t('Inventory.InventoryProduct.AverageLastFourWeeks'), filter: 'agNumberColumnFilter', valueGetter: this.avgLast4WeeksGetter },
                { hide: this.isMobile || !this.readInventoryPermission, colId: 'inventory', headerName: this.$t('Inventory.InventoryProduct.Inventory'), headerTooltip: this.$t('Inventory.InventoryProduct.Inventory'), filter: 'agNumberColumnFilter', valueGetter: this.inventoryQtyGetter },
            ];
        },
        rowData(){
            if(!this.vendorProducts)
                return []

            return this.vendorProducts.map((p) => {
                let selected = false;
                if(this.itemUpcs){
                    selected = this.itemUpcs.includes(p.itemUPC)
                }

                return { ...p, selected: selected }
            })
        },
        readInventoryPermission(){
            return this.hasPermission([permissionsDictionary.INVENTORY_READ])
        }
    },

    watch:{
        productsAvgLast4Weeks(){
            if(!this.gridOptions.api)
                return

            this.gridOptions.api.refreshCells({
                force: true,
                columns: ['avgLast4Weeks'],
            })
        },
        productsInventories(){
            if(!this.gridOptions.api)
                return

            this.gridOptions.api.refreshCells({
                force: true,
                columns: ['inventory'],
            })
        }
    },

    methods: {
        ...mapActions('Product', ['fetchProductsWithCosts', '', 'fetchProductsAverageLastWeeks']),
        ...mapActions('Inventory', ['fetchProductsInventories']),
        async loadData(){
            await this.loadVendorProducts();
            if(!this.vendorProducts || this.vendorProducts.length === 0)
                return

            this.loadProductsAvgLast4Weeks();
            this.loadProductsInventory();   
        },

        async loadVendorProducts(){
            const filters = [{ column: 'VendorCode', operator: Operators.equal.value, value: [this.vendorCode] }];
            const params = { getChildren: false, filters: filters };
            this.vendorProducts = await this.fetchProductsWithCosts(params);
        },

        async loadProductsAvgLast4Weeks(){
            const upcs = [... new Set(this.vendorProducts.map(p => p.itemUPC))];
            this.productsAvgLast4Weeks = await this.fetchProductsAverageLastWeeks({ itemUpcs: upcs, nbOfWeeks: 4 });
        },

        async loadProductsInventory(){
            if(!this.readInventoryPermission)
                return

            const upcs = [... new Set(this.vendorProducts.map(p => p.itemUPC))];
            const inventories = await this.fetchProductsInventories(upcs);

            this.productsInventories = inventories?.reduce((inventories, inventory) => {
                inventories[inventory.itemUpc] = inventory;
                return inventories;
            }, {})
        },

        filtersChanged(filters){
            this.filters = filters;
        },
        inventoryQtyGetter(params) {
            const productInventory = this.productsInventories && this.productsInventories[params.data.itemUPC];
            if(!productInventory || productInventory.qty === null)
                return this.$t('General.NotAvailable')

            return productInventory.qty
        },
        avgLast4WeeksGetter(params){
            const productAvgLast4Weeks = this.productsAvgLast4Weeks && this.productsAvgLast4Weeks[params.data.itemUPC];
            if(!productAvgLast4Weeks || productAvgLast4Weeks.quantity === null)
                return this.$t('General.NotAvailable')

            return productAvgLast4Weeks.quantity
        }
    }
}
</script>
