import Api from '@/core/services/_api.config';
import { convertPromotionItemToApiPromotionItem, convertApiPromotionItemToPromotionItem } from '@/backoffice/modules/promotion/domain/promotion.js'

const promotionApiRoute = '/api/Promotion';

async function createPromotion(promotion) {
    try{
        return await Api().post(promotionApiRoute, promotion);
    }
    catch(e){
        return { message: e.message, data: null, errorSource: e }
    }
}

async function updatePromotion(promotion) {
    return await Api().put(promotionApiRoute, promotion);
}

async function deletePromotion(promotion) {
    try{
        return await Api().delete(promotionApiRoute, { data: promotion });
    }
    catch(e){
        return { message: e.message, data: null, errorSource: e }
    }
}

async function getPromotions() {
    try{
        return await Api().get(`${promotionApiRoute}`);
    }
    catch(e){
        return { message: e.message, data: null, errorSource: e }
    }
}

async function searchPromotionsByProduct(searchTerms){
    try{
        return await Api().get(`${promotionApiRoute}/searchByProduct`, { params: {searchTerms}});
    }
    catch(e){
        return { message: e.message, data: null, errorSource: e }
    }
}

async function getPromotionById(id) {
    try{
        return await Api().get(`${promotionApiRoute}/${id}`);
    }
    catch(e){
        return { message: e.message, data: null, errorSource: e }
    }
}

async function getPromotionScopes() {
    try{
        return await Api().get(`${promotionApiRoute}/promotionScopes`);
    }
    catch(e){
        return { message: e.message, data: null, errorSource: e }
    }
}

async function addPromotionItems(promotionItems) {
    const response = await Api().post(`${promotionApiRoute}/promotionItems`, promotionItems.map(convertPromotionItemToApiPromotionItem));
    return response.data.map(convertApiPromotionItemToPromotionItem)
}

async function deletePromotionItem(promotionItemId){
    try{
        return await Api().delete(`${promotionApiRoute}/promotionItems/${promotionItemId}`);
    }
    catch(e){
        return { message: e.message, data: null, errorSource: e }
    }
}

export const promotionClient = {
    createPromotion,
    updatePromotion,
    deletePromotion,
    getPromotions,
    getPromotionById,
    getPromotionScopes,
    addPromotionItems,
    deletePromotionItem,
    searchPromotionsByProduct
};
