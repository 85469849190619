<template src="./direct-receiving.html"></template>

<style lang="scss">
@import './direct-receiving.scss';
</style>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import cloneDeep from 'lodash/cloneDeep';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import receivingInfo from '@/backoffice/modules/purchase/components/receiving-info/receiving-info.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import collapse from '@/core/components/common/collapse/collapse.vue';
import lineGroupRenderer from '@/backoffice/modules/purchase/pages/receivings/receiving/receiving-renderer/line-group-renderer.vue';
import receivingProductScan from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product-scan/direct-receiving-product-scan.vue';
import receivingProduct from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product/direct-receiving-product.vue';
import tabs from '@/core/components/common/tabs/tabs.vue';
import purchaseVendorProducts from '@/backoffice/modules/purchase/components/purchase-vendor-products/purchase-vendor-products.vue';
import { AgFilter, GridId } from '@/core/components/common/grid/grid.const.js'

export default {
  name: 'Direct-Receiving',
  components: {
    searchBar,
    receivingInfo,
    grid,
    collapse,
    tabs,
    purchaseVendorProducts,
    lineGroupRenderer
  },

  props: {
    id: [Number, String]
  },

  mounted(){
    this.loadReceiving();
  },

  data(){
    return {
      canUserInteractWithUI: true,
      gridOptions: {
        headerHeight: 50,
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
          sortable: true
        },
        rowSelection: 'single',
        getRowId: (params) => params.data?.id,
        overlayNoRowsTemplate: '<span>' + this.$t('Purchase.Messages.NoReceivingData') + '</span>',
        groupDisplayType: 'groupRows',
        groupRowRenderer: "lineGroupRenderer",
        groupRowRendererParams: {
          label: this.$t('Purchase.Receiving.DirectReceiving')
        },
        groupDefaultExpanded: 1,
      },
      filters: [],
      rowFunctions: {
        hasCostChanged: data => (data?.initialUnitCost !== data?.costReceived && data?.costReceived !== 0) || (data?.qtyReceived !== 0 && data?.costReceived === 0),
        totalValue: data => data?.qtyReceived * data?.costReceived
      },
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Receiving', ['getReceiving', 'getReceivingGridTabIndex']),
    ...mapGetters('Account', ['getCurrentTargetLayerId']),
    gridId(){
      return GridId.DirectReceivings
    },
    receivingId() {
      return Number.parseInt(this.id);
    },
    receivingNO() {
      return this.getReceiving?.receivingNO || '';
    },
    isReadOnly() {
      if (!this.getReceiving)
        return true;

      return !this.getReceiving.canUpdate || this.getReceiving.receivingStatus !== ReceivingStatus.OPEN;
    },
    showActions(){
      if (!this.getReceiving)
        return false;

      return this.getReceiving.receivingStatus !== ReceivingStatus.VOID;
    },
    receivingTabs(){
      const tabs = [this.$t('Purchase.Receiving.ReceivingDetails')]
      if(!this.isReadOnly){
        tabs.push(this.$t('Purchase.VendorProducts'))
      }
      return tabs
    },
    rowData(){
      if (!this.getReceiving?.receivingBodies)
        return []

      const receivingNO = this.getReceiving.receivingNO

      return this.getReceiving.receivingBodies.map(rb => (
        {
          id: rb.id,
          receivingNO: receivingNO,
          description: rb.purchaseBodyInfo?.itemDescription,
          upc: rb.purchaseBodyInfo?.itemUPC,
          unitQty: rb.unitQty,
          uom: rb.purchaseBodyInfo?.uomDescription,
          initialUnitCost: rb.unitCost - rb.unitCostVariance,
          qtyReceived: rb.qty,
          costReceived: rb.unitCost
        })
      )
    },
    purchaseVendorProps(){
      return {
        itemUpcs: this.getReceiving?.receivingBodies?.map(ob => ob.purchaseBodyInfo?.itemUPC),
        vendorCode: this.getReceiving?.vendorCode
      } 
    },
    columnDefs(){
      return [
        { 
          field: 'description',
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Description'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Description'),
          cellRenderer: this.isMobile ? 'detailRenderer' : 'clickCallbackRenderer',
          cellRendererParams: {
            callback: data => { if(!this.isReadOnly) this.$sidePanel.show([{ component: receivingProduct }], { itemUPC: data.upc })},
            template: 'ActionAndInformation',
            getInformation: data => data.upc
          },
          cellClass: 'expend-cell',
          width: this.isMobile ? 125 : null,
          filter: AgFilter.TextColumnFilter,
          enableRowGroup: false
        },
        { 
          hide: this.isMobile,
          colId: 'upc',
          field: 'upc',
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.ItemUPC'),
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.ItemUPC'),
          filter: AgFilter.TextColumnFilter,
          enableRowGroup: false
        },
        { 
          hide: this.isMobile,
          colId: 'unitQty',
          field: 'unitQty',
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UnitQty'),
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UnitQty'),
          filter: AgFilter.NumberColumnFilter
        },
        {
          hide: this.isMobile,
          colId: 'uom',
          field: 'uom',
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UOM'),
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UOM'),
          filter: AgFilter.SetColumnFilter
        },
        {
          hide: this.isMobile,
          colId: 'initialUnitCost',
          field: 'initialUnitCost',
          filter: AgFilter.NumberColumnFilter,
          valueFormatter: formatters.currencyFormatter,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Cost'),
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Cost'),
          cellClass: 'purchase-cell'
        },
        { 
          colId: 'qtyReceived',
          field: 'qtyReceived',
          cellRenderer: 'integerInputRenderer',
          cellRendererParams: {
            canUserInteractWithUI: () => this.canUserInteractWithUI && !this.isReadOnly,
            getSecondInfo: data => this.isMobile ? data.qtyToReceive : undefined
          },
          onCellValueChanged: e => this.changeQuantity(e.data.id, e.newValue),
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.QtyReceived'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.QtyReceived'),
          cellClass: !this.isMobile ? 'purchase-cell' : undefined,
          width: this.isMobile ? 90 : undefined,
          suppressMenu: this.isMobile,
          resizable: this.isMobile,
          filter: AgFilter.NumberColumnFilter,
          enableRowGroup: false
        },
        { 
          colId: 'costReceived',
          field: 'costReceived',
          cellRenderer: 'currencyInputRenderer',
          cellRendererParams: {
            canUserInteractWithUI: () => this.canUserInteractWithUI && !this.isReadOnly,
            getSecondInfo: data => this.isMobile ? data.initialUnitCost : undefined,
            decimalConfigs: {
              locale: this.$i18n.locale,
              currency: null,
              distractionFree: false,
              precision: 2
            }
          },
          onCellValueChanged: e => this.changeCost(e.data.id, e.newValue),
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.CostReceived'),
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.CostReceived'),
          cellClass: !this.isMobile ? 'purchase-cell' : undefined,
          cellClassRules: {
            'purchase-cell--indeterminate': params => this.rowFunctions.hasCostChanged(params.data)
          },
          width: this.isMobile ? 90 : undefined,
          suppressMenu: this.isMobile,
          resizable: this.isMobile,
          filter: AgFilter.NumberColumnFilter,
          enableRowGroup: false
        },
        { 
          hide: this.isMobile,
          colId: 'total',
          filter: AgFilter.NumberColumnFilter, 
          valueGetter: params => this.rowFunctions.totalValue(params.data),
          valueFormatter: formatters.currencyFormatter, 
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Total'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Total'),
          cellClassRules: {
            'purchase-cell--indeterminate': params => this.rowFunctions.hasCostChanged(params.data),
          }
        },
        { 
          colId: 'menu',
          field: 'upc',
          headerName: '',
          hide: this.isMobile,
          width: 100,
          resizable: false,
          sortable: false,
          enableRowGroup: false,
          enableValue: false,
          enablePivot: false,
          floatingFilter: false,
          suppressColumnsToolPanel: true,
          filter: null,
          suppressMovable: true,
          cellClass:'ag-cell-overflow',
          cellRenderer: 'menuRenderer',
          cellRendererParams: {
            alwaysVisible: true,
            openLeft: true,
            hideValue: true,
            canUpdate: () => this.getReceiving.canUpdate && !this.isReadOnly,
            canDelete: () => this.getReceiving.canDelete && !this.isReadOnly,
            editEntity: (params) => this.openProductSP(params.data.upc),
            delete: (params) => this.deleteBody(params.data)
          },
          pinned: 'right'
        }
      ];
    },
    
    selectedTab: {
      get() {
        return this.getReceivingGridTabIndex
      },
      set(newValue){
        this.setReceivingGridTabIndex(newValue)
      }
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.$router.push({ name: 'directReceivings' });
    },
    isReadOnly(){
      if(this.gridOptions.api)
        this.$nextTick(() => this.gridOptions.api.sizeColumnsToFit());
    }
  },

  beforeDestroy() {
    this.setReceiving(null);
  },

  methods: {
    ...mapActions('Receiving', ['fetchReceiving', 'saveReceiving', 'closeDirectReceiving']),
    ...mapActions('Product', ['fetchProduct', 'fetchProductCostsByVendor', 'updateItemsForSale']),
    ...mapMutations('Receiving', {
      setReceiving: 'SET_RECEIVING',
      setReceivingGridTabIndex: 'SET_RECEIVING_GRID_TAB_INDEX'
    }),
    ...mapMutations('Breadcrumb', {
      setBreadcrumbContext: 'SET_CONTEXT'
    }),

    async loadReceiving() {
      try {
        await this.fetchReceiving(this.receivingId);
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Purchase.Messages.GetReceivingError')
        });
      }

      if (this.getReceiving.id) {
        this.setBreadcrumbContext({ receiving: this.getReceiving })
      }
    },

    async deleteBody(receivingBody) {
      const result = await prompts.warning({html: this.$t('Purchase.Messages.DeleteReceivingBody')});
      if(!result.isConfirmed)
        return

      const receiving = cloneDeep(this.getReceiving)
      const receivingBodyIndex = receiving.receivingBodies.findIndex(b => b.id === receivingBody.id);
      receiving.receivingBodies[receivingBodyIndex].remove = true;
      receiving.receivingBodies[receivingBodyIndex].ignore = false;
      
      try{
        await this.saveReceiving(receiving);
      }
      catch(error){
        prompts.error({
          text: this.$t('Purchase.Messages.DeleteBodyError')
        });
      }
    },

    async openProductSP(itemUpc) {
      const product = await this.fetchProduct(itemUpc);
      await this.fetchProductCostsByVendor({product: product, vendorCode: this.getReceiving.vendorCode});
      this.$sidePanel.show([{ component: receivingProduct }]);
    },

    addItem() {
      this.$sidePanel.show([{ component: receivingProductScan }], null, { backgroundIsDisabled: true });
    },
    
    async changeQuantity(id, quantityReceived) {
      const receiving = cloneDeep(this.getReceiving);
      const receivingBodyIndex = receiving.receivingBodies.findIndex(rb => rb.id === id)
      receiving.receivingBodies[receivingBodyIndex].ignore = false
      receiving.receivingBodies[receivingBodyIndex].qty = quantityReceived
      receiving.receivingBodies[receivingBodyIndex].cost = receiving.receivingBodies[receivingBodyIndex].unitCost * quantityReceived

      await this.save(receiving);
    },
    async changeCost(id, costReceived) {
      const receiving = cloneDeep(this.getReceiving);
      const receivingBodyIndex = receiving.receivingBodies.findIndex(rb => rb.id === id)

      const initialUnitCost = receiving.receivingBodies[receivingBodyIndex].unitCost
      const initialUnitCostVariance = receiving.receivingBodies[receivingBodyIndex].unitCostVariance

      receiving.receivingBodies[receivingBodyIndex].ignore = false
      receiving.receivingBodies[receivingBodyIndex].unitCost = costReceived
      receiving.receivingBodies[receivingBodyIndex].cost = receiving.receivingBodies[receivingBodyIndex].qty * costReceived
      receiving.receivingBodies[receivingBodyIndex].unitCostVariance = initialUnitCostVariance + costReceived - initialUnitCost

      await this.save(receiving);
    },

    async save(receiving) {
      this.canUserInteractWithUI = false

      try {
        await this.saveReceiving(receiving);
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Purchase.Messages.SaveReceivingError')
        });
      }

      this.canUserInteractWithUI = true;
    },

    async close(){
      const result = await prompts.warning({html: this.$t('Purchase.Messages.CloseReceiving')});
      if (!result.isConfirmed)
        return;

      this.canUserInteractWithUI = false

      try{
        await this.closeDirectReceiving(this.receivingId)
      }
      catch(error){
        if(error?.data?.message === 'CLOSE_VOIDED_RECEPTION')
          prompts.error({ text: this.$t('Purchase.Messages.CloseVoidedReceiving') });
        else if(error?.status === 403)
          prompts.error({ text: this.$t('Purchase.Messages.ForbiddenAction') });
        else
          prompts.error({ text: this.$t('Purchase.Messages.CloseReceivingError') });
        
        return
      }

      await this.validateAndUpdateItemsForSale();

      this.canUserInteractWithUI = true
    },
    // dirt, should be moved to backend
    async validateAndUpdateItemsForSale() {
      const itemsReceived = this.getReceiving.receivingBodies.reduce((itemsReceived, body) => {
        if (body.qty > 0) {
          itemsReceived.upcs.push(body.purchaseBodyInfo.itemUPC);
          itemsReceived.qtyReceived[body.purchaseBodyInfo.itemUPC] = body.qty * body.unitQty;
        }

        return itemsReceived;
      }, { upcs: [], qtyReceived: {} });

      if (itemsReceived.upcs.length == 0) 
        return;

      const itemsUpcSaleStatusChanged = itemsReceived.upcs.filter(upc => itemsReceived.qtyReceived[upc] > 0 );

      if (itemsUpcSaleStatusChanged.length == 0)
        return;

      try {
        await this.updateItemsForSale(itemsUpcSaleStatusChanged);
      } 
      catch (error) {
        prompts.error({ text: this.$t('Inventory.Messages.ErrorOnInventoryManagement') });
      }      
    },
    async voidReceiving(){
      const result = await prompts.warning({html: this.$t('Purchase.Messages.VoidReceiving')});
      if (!result.isConfirmed)
        return;

      const receiving = cloneDeep(this.getReceiving);
      receiving.receivingStatus = ReceivingStatus.VOID;

      this.canUserInteractWithUI = false

      try{
        await this.saveReceiving(receiving);
      }
      catch(error){
        prompts.error({ text: this.$t('Purchase.Messages.VoidReceivingError') });
      }
    },

    scanProduct() {
      this.$sidePanel.show([{ component: receivingProductScan }], null, { backgroundIsDisabled: true });
    },

    filtersChanged(filters){
      this.filters = filters;
    }
  }
}
</script>