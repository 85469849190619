import { itemClient } from "@/backoffice/modules/item/item.client.js"
import { convertApiItemToItem, hasPrice, isForSale } from '@/backoffice/modules/item/domain/item.js'

async function getItemsWithPrice(params) {
    const response = await itemClient.getProducts(params);

    if(response.status === 200) {
      const products = response.data.map(d => convertApiItemToItem(d));
      return products.filter(hasPrice).filter(isForSale)
    }
    else {
      throw response;
    }
}

export const ItemService = {
    getItemsWithPrice
}