<template src="./main.html"></template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import agGridGetters from '@/core/components/common/grid/getters/getters.js';
import { OrderStatus, calculateOrdersProductCount } from '@/backoffice/modules/purchase/domain/purchase.js';
import orderManagement from '@/backoffice/modules/purchase/components/sidePanel/orders/order-management/order-management.vue';
import creationButton from '@/core/components/common/creation-button/creation-button.vue';
import { GridId, AgFilter, AgFilterType, AgAllowedAggFuncs, MultiFilterParams } from '@/core/components/common/grid/grid.const.js'
import grid from '@/core/components/common/grid/persisted-grid.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import sliderList from '@/core/components/common/slider-list/slider-list.vue'
import information from '@/core/components/common/slider-list/templates/information/information.vue'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export default {
  name: 'Orders',
  components: {
    searchBar,
    sliderList,
    information,
    grid,
    creationButton
  },
  data() {
    return {
      gridOptions: {
        getRowId: (params) => params.data?.id,
      },
      defaultFilters: {
        orderStatus: {
          filterType: AgFilterType.Set,
          values: [
            OrderStatus.OPEN,
            OrderStatus.PENDING,
            OrderStatus.PARTIALLYRECEIVED
          ]
        }
      },
      filters: []
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
    ...mapGetters('Order', ['getOrders']),

    canCreateOrder() {
      return this.hasPermission([permissionsDictionary.ORDER_CUD])
    },
    ordersWithProductCount(){
      return calculateOrdersProductCount(this.getOrders)
    },
    openOrders(){
      if(!this.ordersWithProductCount)
        return []
      
      return this.ordersWithProductCount.filter(o => o.orderStatus == OrderStatus.OPEN).sort((left, right) => new Date(right.createdDatetime) - new Date(left.createdDatetime));
    },
    showNoRowsOverlay: function() {
      return this.gridOptions.api ? this.gridOptions.api.getDisplayedRowCount() == 0 : false;
    },
    getGridId() {
      return GridId.Orders
    },
    columnDefs() {
      return [
        {
          field: 'orderNO',
          filterParams: MultiFilterParams.NumberFilters,
          sort: 'desc', 
          headerName: !this.isMobile ? this.$t('Purchase.Order.Columns.OrderNO') : '#',
          headerTooltip: this.$t('Purchase.Order.Columns.OrderNO'),
          enableRowGroup: false,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          cellRenderer: 'linkRenderer',
          cellRendererParams: (params) => {
            return {
              route: { name: 'order', params: { id: params?.data?.id } }
            }
          }
        },
        { hide: false, field: 'vendorName', headerName: this.$t('Purchase.Order.Columns.VendorName'), headerTooltip: this.$t('Purchase.Order.Columns.VendorName'), valueGetter: (params) => params?.data?.vendorName, filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: false, field: 'createdDate', filter: AgFilter.DateColumnFilter, valueGetter: agGridGetters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.createdDate && new Date(params.data.createdDate), headerName: this.$t('Purchase.Order.Columns.CreatedDate'), headerTooltip: this.$t('Purchase.Order.Columns.CreatedDate') },
        { hide: this.isMobile, field: 'receivedDate', filter: AgFilter.DateColumnFilter, valueGetter: agGridGetters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.receivedDate && new Date(params.data.receivedDate), headerName: this.$t('Purchase.Order.Columns.ReceivedDate'), headerTooltip: this.$t('Purchase.Order.Columns.ReceivedDate') },
        { hide: false, field: 'orderStatus', filter: AgFilter.SetColumnFilter, filterParams: { valueFormatter: (params) => this.$t(`Purchase.Order.Status.${params.value}`) }, valueGetter: params => { return agGridGetters.valueTranslateGetter(params, 'Purchase.Order.Status'); }, filterValueGetter: (params) => params?.data?.orderStatus, headerName: this.$t('Purchase.Order.Columns.OrderStatus'), headerTooltip: this.$t('Purchase.Order.Columns.OrderStatus'), allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'productCount', filterParams: MultiFilterParams.NumberFilters, headerName: this.$t('Purchase.Order.Columns.OrderBodySums'), headerTooltip: this.$t('Purchase.Order.Columns.OrderBodySums') },
        { hide: this.isMobile, field: 'userUsername', filterParams: MultiFilterParams.NormalizeTextFilters, headerName: this.$t('Purchase.Order.Columns.UserUsername'), headerTooltip: this.$t('Purchase.Order.Columns.UserUsername'), allowedAggFuncs: AgAllowedAggFuncs.Text },
      ]
    }
  },
  
  watch: {
    getCurrentTargetLayerId() {
      this.init();
    },
    showNoRowsOverlay(showNoRowsOverlay){
      if(this.gridOptions.api){      
        if (showNoRowsOverlay)
          this.gridOptions?.api?.showNoRowsOverlay();
        else
          this.gridOptions?.api?.hideOverlay();
      }
    }
  },
  beforeDestroy(){
    this.resetOrders()
  }, 

  methods: {
    ...mapActions('Order', ['fetchOrders']),
    ...mapMutations('Order',{resetOrders: 'RESET_ORDERS'}),

    onGridReady(params) {
      this.gridOptions.api = params.api
      this.init()
    },

    async init(){
      try {
        await this.fetchOrders();
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Purchase.Messages.GetOrdersError')
        });
      }
    },

    newOrder(){
      this.$sidePanel.show([{ component: orderManagement }]);
    },
    filtersChanged(filters){
      this.filters = filters;
    }
  }
}
</script>