import clientAccountClient from '@/backoffice/modules/clientAccount/clients/clientAccount.client.js'
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import { Client, CanadianProvinces, USAStates, Countries } from '@/backoffice/modules/clientAccount/domain/client.js'
import cloneDeep from 'lodash/cloneDeep';

async function createOrUpdate(client, operationType){

    switch(operationType){
        case OperationType.Creation:
            return await clientAccountClient.createClient(client)
        case OperationType.Edition:
            return await clientAccountClient.updateClient(client)
    }
}

function getStates(country){
    if(country)
        return country === Countries.USA ? Object.values(USAStates) : Object.values(CanadianProvinces)

    return []
}

function getZipCodeMask(country){
    return country === Countries.USA ? '#####-###' : 'A#A #A#'
}

async function buildClient(operationType, client, creationStoreId, creationUserId){
    if(operationType === OperationType.Edition){
        return cloneDeep(client)
    }

    const clientTargetConfig = await clientAccountClient.getClientTargetConfig()
    return new Client(
        {
            isActive: true, 
            targetId: clientTargetConfig._TargetID,
            targetDescription: clientTargetConfig.description,
            creationStoreId,
            creationUserId
        })
}

export const clientAccountServices = {
    createOrUpdate,
    getStates,
    getZipCodeMask,
    buildClient
}