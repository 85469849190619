import { promotionClient } from "@/backoffice/modules/promotion/services/promotion.client";
import { convertApiPromotionToPromotion, convertPromotionToApiPromotion, convertApiPromotionScopeToPromotionScope, 
         updatePromotionIdsFromApiPromotion, DuplicatePromoCodeException } from '@/backoffice/modules/promotion/domain/promotion.js'
import { getCurrentLanguageDescription } from '@/core/functions/descriptions-helper.js';

const getDefaultState = () => {
  return {
    promotions: [],
    promotion: null,
    promotionScopes:[],
    promotionItems: [],
    operationType: null
  };
};

export default {  
  namespaced: true,
  name: "Promotion",
  state: getDefaultState(),
  getters: {
    getPromotions: (state) => (includeDeleted) => {
      if (!includeDeleted) {
        return state.promotions.filter(promotion => !promotion.isDeleted);
      }
      return state.promotions;
    },
    getPromotion: (state) => {
      return state.promotion;
    },
    getPromotionDiscount: (state) => {
      return state.promotion?.discount;
    },
    getPromotionItems: (state) => {
      return state.promotion?.discount?.items || []
    },
    getPromotionDescription: (state) => {
      const description = getCurrentLanguageDescription(state.promotion.descriptions)
      return description?.value
    },
    getOperationType: (state) => {
      return state.operationType
    }
  },

  mutations: {
    RESET_STORE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions;
    },
    SET_PROMOTION(state, promotion) {
      state.promotion = promotion;
    },
    UPDATE_PROMOTION_IN_PROMOTIONS(state, promotion) {
      let promo = state.promotions.find(promo => promo.id === promotion.id)
      promo = Object.assign(promo, promotion)
    },
    DELETE_PROMOTION(state, promotion){
      const i = state.promotions.findIndex(p => p.id === promotion.id)
      if (i > -1) {
        state.promotions[i].isDeleted = true
      }
    },
    DELETE_PROMOTION_ITEM(state,itemId){
      const index = state.promotion.discount.items.findIndex(item => item.id === itemId);
      if(index > -1){
        state.promotion.discount.items.splice(index, 1);
      }
    },
    SET_PROMOTION_SCOPES(state, promotionScopes) {
      state.promotionScopes = promotionScopes;
    },
    ADD_PROMOTION_ITEMS(state, items){
      if(state.promotion.discount){
        state.promotion.discount.items.push(...items)
      }
    },
    SET_OPERATION_TYPE (state, operationType) {
      state.operationType = operationType
    }
  },

  actions: {
    async fetchPromotions({ commit }) {
      const response = await promotionClient.getPromotions();
      if (response.status >= 200 && response.status <= 299) {
        const promotions = response.data.map(convertApiPromotionToPromotion)
        commit("SET_PROMOTIONS", promotions);
      } else {
        commit("SET_PROMOTIONS", []);
        throw response;
      }
    },
    async fetchPromotion({ commit }, promotionId) {
      const response = await promotionClient.getPromotionById(promotionId);
      if (response.status >= 200 && response.status <= 299) {
        const promotion = convertApiPromotionToPromotion(response.data)
        commit("SET_PROMOTION", promotion);
      } else {
        commit("SET_PROMOTION", {});
        throw response;
      }
    },
    async savePromotion({ state, commit }, promotion) {
      let response = null;
      const isUpdate = promotion.id > 0

      if (isUpdate) {
        response = await promotionClient.updatePromotion(convertPromotionToApiPromotion(promotion));
      } else {
        response = await promotionClient.createPromotion(convertPromotionToApiPromotion(promotion));
      }
      if(response.status !== 200){
        if(response.errorSource?.response?.data?.errorCode === 'DuplicatedException')
          throw new DuplicatePromoCodeException()
        else
          throw response;
      }

      updatePromotionIdsFromApiPromotion(promotion, response.data)
      if (isUpdate) {
        commit("UPDATE_PROMOTION_IN_PROMOTIONS", promotion);
      } else {
        commit("SET_PROMOTIONS", [ ...state.promotions, promotion]);
      }

      commit("SET_PROMOTION", promotion);
    },
    async deletePromotion({ commit }, promotion) {  
      const response = await promotionClient.deletePromotion(convertPromotionToApiPromotion(promotion));

      if (response.status == 200) {
        commit("DELETE_PROMOTION", promotion);
      } else {
        throw response;
      }
    },

    async deletePromotionItem({commit}, itemId){
      const response = await promotionClient.deletePromotionItem(itemId);
      if(response.status == 200){
        commit('DELETE_PROMOTION_ITEM', itemId);
      }else{
        throw response;
      }
    },
    async getPromotionScopes({ commit }) {

      const response = await promotionClient.getPromotionScopes()

      if (response.status >= 200 && response.status <= 299) {
        const promotionScopes = response.data.map(convertApiPromotionScopeToPromotionScope)
        commit("SET_PROMOTION_SCOPES", promotionScopes)
        return promotionScopes
      } else {
        throw response
      }
    },
    async searchPromotionsByProduct({ commit }, searchTerms) {
      const response = await promotionClient.searchPromotionsByProduct(searchTerms);
      if (response.status >= 200 && response.status <= 299) {
        const promotions = response.data.map(convertApiPromotionToPromotion)
        commit("SET_PROMOTIONS", promotions);
      } else {
        commit("SET_PROMOTIONS", []);
        throw response;
      }
    },
  }
};
