import i18n from '@/i18n';

export const getLanguageDescription = (descriptions = [], language) => {
  return descriptions.find(d => d.language && d.language.localeCompare(language, undefined, { sensitivity: 'accent' }) == 0);
}
export const hasLanguageDescription = (descriptions = [], language) => {
  return descriptions.some(d => d.language && d.language.localeCompare(language, undefined, { sensitivity: 'accent' }) == 0);
}

export const getDescription = (descriptions = [], language = i18n.locale) => {
  const currentDescription = getLanguageDescription(descriptions, language)
  if (currentDescription)
    return currentDescription.value

  return descriptions[0]?.value
}

export const getCurrentLanguageDescription = (descriptions) => {
  return getLanguageDescription(descriptions, i18n.locale);
}
export const hasAnyDescription = (descriptions, field) => {
  return descriptions.some(desc => desc[field])
}