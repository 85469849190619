<template src="./main.html"></template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import manageColumns from '@/backoffice/modules/reporting/mixins/manage-columns.js';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import printReport from '@/backoffice/modules/reporting/mixins/print-report.js';
import documentServices from '@/backoffice/modules/inventory/services/document.services.js';
import getters from '@/core/components/common/grid/getters/getters.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import inventoryAdjustment from  '@/backoffice/modules/inventory/pages/main/inventory-adjustment/inventory-adjustment.vue';
import inventoryCount from  '@/backoffice/modules/inventory/pages/main/inventory-count/inventory-count.vue';
import sliderList from '@/core/components/common/slider-list/slider-list.vue';
import information from '@/core/components/common/slider-list/templates/information/information.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import { GridId, AgFilter, AgAllowedAggFuncs, MultiFilterParams } from '@/core/components/common/grid/grid.const.js'

export default {
  name: 'MainInventory',
  mixins: [manageColumns, extractTime, printReport],
  components: {
    sliderList,
    information ,
    grid,
    searchBar
  },

  data() {
    return {
      gridOptions: {
        getRowId: (params) => params.data?.documentID
      },
      gridApi: null,
      filters: [],
      inventorySidePanel: {
        'InventoryAdjustment': [{ component: inventoryAdjustment }],
        'InventoryCount': [{ component: inventoryCount }]
      }
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['hasPermission', 'getCurrentTargetLayerId']),
    ...mapGetters('Inventory', ['getOpenDocuments', 'getClosedDocuments']),
    getGridId() {
      return GridId.Inventories
    },
    openDocuments() {
      return this.getOpenDocuments.slice().sort((left, right) => new Date(right.documentDatetime) - new Date(left.documentDatetime));
    },
    canCreateDocument() {
      return this.hasPermission(['INVENTORYDOCUMENT_CUD'])
    },
    columnDefs() {
      return [
        { hide: true, field: 'documentNO', headerName: this.$t('Document.Columns.DocumentNO'), headerTooltip: this.$t('Document.Columns.DocumentNO'), filterParams: MultiFilterParams.NumberFilters },
        { 
          field: 'documentType', 
          headerName: this.$t('Document.Columns.DocumentType'), 
          width: 100, 
          headerTooltip: this.$t('Document.Columns.DocumentType'),
          filter: AgFilter.SetColumnFilter, 
          cellRenderer: 'iconRenderer',
          cellRendererParams: {
            getIconValue: this.getIconValue
          },
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filterValueGetter: (params) => this.$t(`Document.Columns.${params?.data?.documentType}`)
        },
        { 
          field: 'description', 
          headerName: this.$t('Document.Columns.Description'), 
          minWidth: 150, 
          headerTooltip: this.$t('Document.Columns.Description'), 
          cellRenderer: 'linkRenderer',
          cellRendererParams: (params) => {
            return {
              route: { name: 'document', params: { id: params?.data?.documentID } }
            }
          },
          enableRowGroup: false,
          filterParams: MultiFilterParams.NormalizeTextFilters,
          allowedAggFuncs: AgAllowedAggFuncs.Text
        },
        { field: 'reason', headerName: this.$t('Document.Columns.Reason'), headerTooltip: this.$t('Document.Columns.Reason'), filter: AgFilter.SetColumnFilter, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: true, field: 'documentStatus', headerName: this.$t('Document.Columns.DocumentStatus'), headerTooltip: this.$t('Document.Columns.DocumentStatus'), filter: AgFilter.SetColumnFilter, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'userName', headerName: this.$t('Document.Columns.UserName'), headerTooltip: this.$t('Document.Columns.UserName'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'documentDate', headerName: this.$t('Document.Columns.DocumentDate'), headerTooltip: this.$t('Document.Columns.DocumentDate'), filter: AgFilter.DateColumnFilter, valueGetter: getters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.documentDate && new Date(params.data.documentDate) },
        { hide: true, field: 'documentDatetime', headerName: this.$t('Document.Columns.DocumentDatetime'), headerTooltip: this.$t('Document.Columns.DocumentDatetime'), filter: AgFilter.DateColumnFilter, filterValueGetter: (params) => params?.data?.documentDatetime && new Date(params.data.documentDatetime) },
        { hide: this.isMobile, field: 'documentLastDate', headerName: this.$t('Document.Columns.DocumentLastDate'), headerTooltip: this.$t('Document.Columns.DocumentLastDate'), filter: AgFilter.DateColumnFilter, valueGetter: getters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.documentLastDate && new Date(params.data.documentLastDate) },
        { hide: true, field: 'documentLastDatetime', headerName: this.$t('Document.Columns.DocumentLastDatetime'), headerTooltip: this.$t('Document.Columns.DocumentLastDatetime'), filter: AgFilter.DateColumnFilter, filterValueGetter: (params) => params?.data?.documentLastDatetime && new Date(params.data.documentLastDatetime), sort: 'desc' },
        { hide: true, field: 'processDate', headerName: this.$t('Document.Columns.ProcessDate'), headerTooltip: this.$t('Document.Columns.ProcessDate'), filter: AgFilter.DateColumnFilter, filterValueGetter: (params) => params?.data?.processDate && new Date(params.data.processDate) },
        { hide: true, field: 'userUsername', headerName: this.$t('Document.Columns.UserUsername'), headerTooltip: this.$t('Document.Columns.UserUsername'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: true, field: 'userEmail', headerName: this.$t('Document.Columns.UserEmail'), headerTooltip: this.$t('Document.Columns.UserEmail'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: true, field: 'currentInventoryQty', headerName: this.$t('DocumentBody.Columns.CurrentInventoryQty'), headerTooltip: this.$t('DocumentBody.Columns.CurrentInventoryQty'), filterParams: MultiFilterParams.NumberFilters },
        { hide: true, field: 'currentInventoryCost', headerName: this.$t('DocumentBody.Columns.CurrentInventoryCost'), headerTooltip: this.$t('DocumentBody.Columns.CurrentInventoryCost'), filterParams: MultiFilterParams.NumberFilters, valueFormatter: formatters.currencyFormatter },
        { hide: true, field: 'qty', headerName: this.$t('DocumentBody.Columns.Qty'), headerTooltip: this.$t('DocumentBody.Columns.Qty'), filterParams: MultiFilterParams.NumberFilters },
        { hide: true, field: 'cost', headerName: this.$t('DocumentBody.Columns.Cost'), headerTooltip: this.$t('DocumentBody.Columns.Cost'), filterParams: MultiFilterParams.NumberFilters, valueFormatter: formatters.currencyFormatter },
        { hide: this.isMobile, field: 'nbOfBodies', headerName: this.$t('DocumentBody.Columns.NbOfBodies'), headerTooltip: this.$t('DocumentBody.Columns.NbOfBodies'), filterParams: MultiFilterParams.NumberFilters },
        { hide: true, field: 'reasonCode', headerName: this.$t('Document.Columns.ReasonCode'), headerTooltip: this.$t('Document.Columns.ReasonCode'), filter: AgFilter.SetColumnFilter, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { field: 'varianceQty', headerName: this.$t('DocumentBody.Columns.VarianceQty'), width: this.isMobile ? 50 : 175, headerTooltip: this.$t('DocumentBody.Columns.VarianceQty'), filterParams: MultiFilterParams.NumberFilters, cellRenderer: 'negativeValueRenderer' },
        { field: 'varianceCost', headerName: this.$t('DocumentBody.Columns.VarianceCost'), width: 175, headerTooltip: this.$t('DocumentBody.Columns.VarianceCost'), filterParams: MultiFilterParams.NumberFilters, cellRenderer: 'negativeAmountRenderer' }
      ]
    },
    rowData(){
      return this.getClosedDocuments
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.getDocuments();
    }
  },
  beforeDestroy(){
    this.resetDocument()
  },
  mounted() {
    this.setDocuments([]);
  },

  methods: {
    ...mapActions('Inventory', ['fetchAggregatedDocuments']),
    ...mapMutations('Inventory', { setDocuments: 'SET_DOCUMENTS', resetDocument:'RESET_DOCUMENTS'  }),

    onGridReady() {
      this.getDocuments();
    },

    async getDocuments(){
      try {
        await this.fetchAggregatedDocuments();
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Inventory.Messages.GetDocumentError')
        });
      }
    },

    getIconValue(data) {
      return documentServices.getIcon(data.documentType);
    },

    getIcon: documentServices.getIcon,

    getFormattedUser(userName) {
      if (!userName)
        return '';

      const nameParts = userName.split(' ');
      let shortName = nameParts[0];
      for (let i = 1, c = nameParts.length; i < c; i++) {
        shortName +=  ` ${nameParts[i].charAt(0)}`;
      }
      
      return shortName;
    },

    onNewDocumentClick(template) {
      this.$sidePanel.show(this.inventorySidePanel[template]);
    },
    
    filtersChanged(filters){
      this.filters = filters;
    }
  }
}
</script>