<template src="./order-related-receivings.html"></template>

<script>
import { mapGetters } from 'vuex';
import grid from '@/core/components/common/grid/grid.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import formatters from '@/core/components/common/grid/formatters/formatters.js';

export default {
    name: 'orderRelatedReceivings',
    components: {
        grid,
        searchBar
    },
    data() {
        return {
            gridOptions: {
                api: null,
                columnApi: null,
                headerHeight: 50,
                rowHeight: 50,
                defaultColDef: {
                    resizable: true,
                    sortable: true
                },
                rowSelection: 'single',
                getRowId: (params) => params.data?.id,
                overlayNoRowsTemplate: '<span>' + this.$t('Purchase.Messages.NoOrderData') + '</span>'
            },
            filters: []
        }
    },
    computed:{        
        ...mapGetters('App', ['isMobile']),
        ...mapGetters('Account', ['getCurrentTargetLayerId']),
        ...mapGetters('Order', ['getOrderRelatedReceivingBodies']),
        columnDefs(){ 
            const cellRenderer = this.isMobile ? 'dropdownRenderer' : 'clickCallbackRenderer';
            const cellRendererParams = this.isMobile ? {
                openSidePanel: (row) => this.$emit('onReceivingClick', row),
                canDelete: false
            } : { callback: (row) => this.$emit('onReceivingClick', row) }
            
            return [
                { field: 'receivingNO', headerName: this.$t('Purchase.Receiving.Columns.ReceivingNO'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingNO'), cellRenderer: cellRenderer, cellRendererParams: cellRendererParams, cellClass: this.isMobile ? "dropdown-cellrenderer" : "" },
                { hide: this.isMobile, field: 'receivedDate', headerName: this.$t('Purchase.Order.Columns.ReceivedDate'), headerTooltip: this.$t('Purchase.Order.Columns.ReceivedDate'), valueFormatter: formatters.datetimeFormatter },
                { hide: this.isMobile, field: 'status', headerName: this.$t('Purchase.Receiving.Columns.ReceivingStatus'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingStatus'), valueGetter: params => { return this.$t(`Purchase.Receiving.Status.${params.data.status}`); } },
                { hide: this.isMobile, field: 'productCount', headerName: this.$t('Purchase.Receiving.Columns.ReceivingBodySums'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingBodySums') },
                { hide: this.isMobile, field: 'qty', headerName: this.$t('Purchase.Order.ReceivedQty'), headerTooltip: this.$t('Purchase.Order.ReceivedQty') },
                { hide: this.isMobile, field: 'username', headerName: this.$t('Purchase.Receiving.Columns.UserUsername'), headerTooltip: this.$t('Purchase.Receiving.Columns.UserUsername') },
                { hide: this.isMobile, field: 'total', headerName: this.$t('Purchase.Receiving.Columns.Cost'), headerTooltip: this.$t('Purchase.Receiving.Columns.Cost'), valueFormatter: formatters.currencyFormatter },
            ];
        },
        rowData(){
            if(!this.getOrderRelatedReceivingBodies)
                return []

            const groupedReceivings = {}

            this.getOrderRelatedReceivingBodies.forEach(rb => {
                const key = rb.receivingID
                
                if(!groupedReceivings[key]) {
                    groupedReceivings[key] = Object.assign({}, 
                        { 
                            id: rb.receivingID,
                            receivingNO: rb.receivingNO,
                            receivedDate: rb.lastModifiedDatetime,
                            status: rb.receivingStatus,
                            productCount: 1,
                            username: rb.userUsername,
                            qty: rb.qty, 
                            total: rb.cost 
                        }
                    );
                } 
                else {
                    groupedReceivings[key].productCount++;
                    groupedReceivings[key].qty += rb.qty;
                    groupedReceivings[key].total += rb.cost;
                }        
            });
            
            return Object.values(groupedReceivings)
        }
    },
    methods: {
        filtersChanged(filters){
            this.filters = filters;
        }
    }
}
</script>
