/* eslint-disable no-console */

import { register } from "register-service-worker";
import vuex from '@/core/store';

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    updated() { 
      vuex.dispatch('App/setNewVersionServiceWorker', true); 
    },
  });
}
