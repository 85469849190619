import Decimal from "decimal.js";

export const PromotionScopeCode = {
  Item: "Item",
  Transaction: "Transaction",
};

export const DiscountTypeCode = {
  FixedPrice: "FixedPrice",
  DollarDiscount: "DollarDiscount",
  PercentDiscount: "PercentDiscount",
  Freebie: "Freebie",
  BundleOffer: "BundleOffer"
};

export const PromotionSearchByOptionEnum = {
  PROMOTION: 'promotion',
  ITEM: 'item'
}

export const CoveredAmount = {
  Subtotal: 'Subtotal',
  Total: 'Total'
}


export const PromotionSearchByOption = [
  { value: PromotionSearchByOptionEnum.PROMOTION, text: 'Promotion.SearchBy.Promotion' },
  { value: PromotionSearchByOptionEnum.ITEM, text: 'Promotion.SearchBy.Item' },
]

export class Promotion {
  constructor() {
    this.id = null;
    this.code = null;
    this.descriptions = [];
    this.startDate = null;
    this.endDate = null;
    this.discount = new PromotionDiscount();
    this.scope = new PromotionScope();
    this.active = true;
    this.instant = false;
    this.maxUsage = null;
    this.totalUsage = 0;
    this.isDeleted = false;
    this.targetId = null;
    this.targetDescription = null;
    this.canUpdate = false;
    this.canDelete = false;
  }
}

export class PromotionDiscount {
  constructor() {
    this.id = null;
    this.value = 0.0;
    this.typeId = 0;
    this.typeCode = null;
    this.discountMinItems = null;
    this.discountMaxItems = null;
    this.items = [];
    this.coveredAmount = CoveredAmount.Subtotal
  }
}

export class PromotionItem {
  constructor() {
    this.id = 0;
    this.upc = null;
    this.discountId = 0;
    this.descriptions = [];
    this.regularPrice = 0;
    this.remove = false;
    this.canUpdate = false;
    this.canDelete = false;
  }
}

export class PromotionScope {
  constructor() {
    this.scopeId = 0;
    this.scopeCode = null;
    this.isSingleDiscount = true;
    this.canAddItems = true;
    this.types = [];
  }
}

export class PromotionDiscountType {
  constructor() {
    this.typeId = 0
    this.typeCode = null
  }
}

export const convertApiPromotionToPromotion = (apiPromotion) => {
  const promotion = new Promotion();

  if(!apiPromotion)
    return promotion;

  promotion.id = apiPromotion.promotionID;
  promotion.targetId = apiPromotion.targetID;
  promotion.code = apiPromotion.promotionCode;
  promotion.scopeId = apiPromotion.promotionScopeID;
  promotion.active = apiPromotion.isActive;
  promotion.instant = apiPromotion.isInstant;
  promotion.startDate = apiPromotion.promotionStart;
  promotion.endDate = apiPromotion.promotionEnd;
  promotion.maxUsage = apiPromotion.maximumUsage || null;
  promotion.totalUsage = apiPromotion.totalUsage;
  promotion.isDeleted = apiPromotion.isDeleted;
  promotion.descriptions = apiPromotion.descriptions.map((description) => ({
    _DescriptionID: description.descriptionID,
    id: description.promotionID,
    language: description.language,
    value: description.value,
  }));
  promotion.discount = convertApiPromotionDiscountToPromotionDiscount(apiPromotion.discounts[0]);
  promotion.scope = convertApiPromotionScopeToPromotionScope(apiPromotion.scope)
  promotion.targetDescription = apiPromotion.targetDescription;
  promotion.canUpdate = apiPromotion.canUpdate;
  promotion.canDelete = apiPromotion.canDelete;

  return promotion;
};

export const convertApiPromotionDiscountToPromotionDiscount = (apiPromotionDiscount) => {
  const discount = new PromotionDiscount();

  if(!apiPromotionDiscount)
    return discount;

  discount.id = apiPromotionDiscount.promotionDiscountID;
  discount.promotionId = apiPromotionDiscount.promotionID;
  discount.typeId = apiPromotionDiscount.promotionDiscountTypeID;
  discount.typeCode = apiPromotionDiscount.promotionDiscountTypeCode;
  discount.value = apiPromotionDiscount.promotionDiscountValue;
  discount.discountMinItems = apiPromotionDiscount.promotionDiscountMinItems;
  discount.discountMaxItems = apiPromotionDiscount.promotionDiscountMaxItems;
  discount.items = apiPromotionDiscount.items.map((item) => convertApiPromotionItemToPromotionItem(item));
  discount.coveredAmount = Object.values(CoveredAmount)[apiPromotionDiscount.coveredAmountType] ?? CoveredAmount.Subtotal
  
  return discount;
};

export const convertApiPromotionItemToPromotionItem = (apiPromotionItem) => {
  const promotionItem = new PromotionItem();

  if(!apiPromotionItem)
    return promotionItem;

  promotionItem.id = apiPromotionItem.promotionItemId;
  promotionItem.upc = apiPromotionItem.itemUPC;
  promotionItem.discountId = apiPromotionItem.discountId;

  if(apiPromotionItem.itemDescriptions)
    promotionItem.descriptions = apiPromotionItem.itemDescriptions.map((description) => ({
        _DescriptionID: description._ItemDescriptionID,
        id: description._ItemID,
        language: description.language,
        value: description.itemDescription1,
      })
    );

  promotionItem.regularPrice = apiPromotionItem.regularPrice;
  promotionItem.remove = false;
  promotionItem.canUpdate = apiPromotionItem.canUpdate;
  promotionItem.canDelete = apiPromotionItem.canDelete;

  return promotionItem;
};

export const convertApiPromotionScopeToPromotionScope = (apiPromotionScope) => {
  const promotionScope = new PromotionScope();

  if(!apiPromotionScope)
    return promotionScope;

  promotionScope.scopeId = apiPromotionScope.promotionScopeID;
  promotionScope.scopeCode = apiPromotionScope.promotionScopeCode;
  promotionScope.isSingleDiscount = apiPromotionScope.singleDiscount;
  promotionScope.canAddItems = apiPromotionScope.acceptItems;
  promotionScope.types = apiPromotionScope.discountTypes.map((promotionDiscountType) => convertApiPromotionDiscountTypeToPromotionDiscountType(promotionDiscountType));
  
  return promotionScope;
};

export const convertApiPromotionDiscountTypeToPromotionDiscountType = (apiPromotionDiscountType) => {
	const promotionDiscountType = new PromotionDiscountType()

  if(!apiPromotionDiscountType)
    return promotionDiscountType;

	promotionDiscountType.typeId = apiPromotionDiscountType.promotionDiscountTypeID
	promotionDiscountType.typeCode = apiPromotionDiscountType.promotionDiscountTypeCode

  return promotionDiscountType;
};

export const convertPromotionToApiPromotion = (promotion) => {
  if(!promotion)
    return;

  const apiPromotion = {
    promotionID: promotion.id,    
    targetID: promotion.targetId,
    promotionCode: promotion.code,
    promotionScopeID: promotion.scope.scopeId,
    isActive: promotion.active,
    isInstant: promotion.instant,
    promotionStart: promotion.startDate,
    promotionEnd: promotion.endDate,
    maximumUsage: promotion.maxUsage,
    descriptions: promotion.descriptions ? promotion.descriptions.map((description) => ({
      descriptionID: description._DescriptionID,
      promotionID: description.id,
      language: description.language,
      value: description.value
    })) : [],
    discounts: [converPromotionDiscountToApiPromotionDiscount(promotion.discount)],
    scope: convertPromotionScopeToApiPromotionScope(promotion.scope)
  };
  
  return apiPromotion;
};

export const converPromotionDiscountToApiPromotionDiscount = (promotionDiscount) => {
  if(!promotionDiscount)
    return;

	const apiPromotionDiscount = {
		promotionDiscountID: promotionDiscount.id,
		promotionID: promotionDiscount.promotionId,
		promotionDiscountTypeID: promotionDiscount.typeId,
		promotionDiscountTypeCode: promotionDiscount.typeCode,
		promotionDiscountValue: promotionDiscount.value,
		promotionDiscountMinItems: promotionDiscount.discountMinItems,
		promotionDiscountMaxItems: promotionDiscount.discountMaxItems,    
		items: promotionDiscount.items.map((item) => convertPromotionItemToApiPromotionItem(item)),
    coveredAmount: promotionDiscount.coveredAmount
	}
	return apiPromotionDiscount
}

export const convertPromotionItemToApiPromotionItem = (promotionItem) => {
  if(!promotionItem)
    return;

	const apiPromotionItem = {
    promotionItemID: promotionItem.id,
    itemUPC: promotionItem.upc,
    promotionDiscountID: promotionItem.discountId,
    isDeleted: promotionItem.isDeleted
	}
	
	return apiPromotionItem
}

export const convertPromotionScopeToApiPromotionScope = (promotionScope) => {
  if(!promotionScope)
    return;

	const apiPromotionScope = {
    promotionScopeID: promotionScope.scopeId,
    promotionScopeCode: promotionScope.scopeCode,
    singleDiscount: promotionScope.isSingleDiscount,
    acceptItems: promotionScope.canAddItems
	}
	
	return apiPromotionScope
}

export const calculateDiscountedPrice = (discount, price) => {

  if(discount.typeCode === DiscountTypeCode.BundleOffer){

    const regularPrices = discount.items.map(i => i.regularPrice ?? 0)
    const itemRegularPriceSum = 
      regularPrices.reduce((sum, value) => sum.add(value), new Decimal(0)).toNumber()

    let discountedPrice = Decimal.div(price, itemRegularPriceSum).mul(discount.value).toDecimalPlaces(2).toNumber()

    const maxRegularPrice = regularPrices.reduce((max, p) => Math.max(max, p), -Infinity)
    if(price === maxRegularPrice){
      const regularPricesWithoutMax = regularPrices.filter(p => p !== price);

      let discountedPriceSum = regularPricesWithoutMax.reduce((sum, p) => sum.add(calculateDiscountedPrice(discount, p)), new Decimal(0))
      discountedPriceSum = discountedPriceSum.add(discountedPrice).toDecimalPlaces(2).toNumber()

      discountedPrice-= (discountedPriceSum - discount.value)
    }

    return discountedPrice
  }

  let value = discount?.value
  if (discount?.typeCode === DiscountTypeCode.PercentDiscount) {
    value = price -  (price * discount.value)
  }
  else if (discount?.typeCode === DiscountTypeCode.DollarDiscount) {
    value = price - discount.value
  }

  return value < 0 ? 0 : value
};

export const updatePromotionIdsFromApiPromotion = (promotion, apiPromotion) => {
  promotion.id = apiPromotion.promotionID;
  for (const apiDescription of apiPromotion.descriptions) {
    const description = promotion.descriptions.find((d) => d.language === apiDescription.language);
    if (description) description._DescriptionID = apiDescription.descriptionID;
  }

  const apiDiscount = apiPromotion.discounts.length > 0 ? apiPromotion.discounts[0] : null;
  if (apiDiscount) promotion.discount.id = apiDiscount.promotionDiscountID;
};

export class DuplicatePromoCodeException extends Error {
  constructor(){
    super('Promotion.Messages.DuplicatedPromotionCode')
    this.name = 'DuplicatePromoCodeException'
  }
}