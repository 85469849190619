<template src="./brand-management.html"></template>

<style lang="scss">
    @import './brand-management.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider, localize } from "vee-validate"
import prompts from '@/core/tools/notifications/notifications'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import multiselect from "vue-multiselect"
import { newBrand } from '@/backoffice/modules/brand/domain/brand.js'
import { getDescription } from '@/core/functions';
import descriptionInput from '@/core/components/common/description-input/description-input.vue'
import scopeDropdown from '@/core/components/common/drop-down/scope-dropdown/scope-dropdown.vue'

export default {
    name: "brand-template",

    components: {
        descriptionInput,
        scopeDropdown,
        ValidationObserver,
        ValidationProvider,
        localize,
        multiselect
    },

    props:{
        templatePayload: Object
    },

    data() {
        return {
            brand: newBrand(),
            brandCopy: null,
            brandKey: 0,
            preventSave: false,
            selectedScope: null,
            multiSelect: {
                value: null,
                options: [],
                trackBy: 'scopeId',
                label: 'description',
                tag: false,
                showLabels: false,
                placeholder: this.$t('Target.TargetDescriptionPh'),
                allowEmpty: false,
                onSelect: this.onScopeChanged,
                onRemove: this.onRemove,
            }
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),

        readOnly() {
            return !this.brand.canUpdate;
        }
    },

    watch: {
        templatePayload: {
            handler(newVal, oldVal) {
                if(newVal && newVal != oldVal) {
                    this.setBrand();
                    this.brandKey++;
                }
            }
        },
        brand: {
            handler(newentity){
                this.$sidePanel.isEdit(!isEqual(newentity, this.brandCopy));
            },
            deep: true
        }
    },

    mounted() {
        localize(this.$i18n.locale);
        this.getCreationScope()
    },

    methods: {
        ...mapActions('Brand', ['getBrandById', 'saveBrand', 'getBrandCreationScope']),

        async getCreationScope() {
            this.multiSelect.options = []

            try {
                this.multiSelect.options = await this.getBrandCreationScope()
            } catch (error) {
                prompts.error({
                    text: error.message
                })
                return
            }

            await this.setBrand()
        },
        async setBrand() {
            if (this.templatePayload.id) {
                let brand
                try {
                    brand = await this.getBrandById(this.templatePayload.id)
                } catch (error) {
                    prompts.error({
                        text: error.message
                    })
                    return
                }

                this.brand = cloneDeep(brand);
                this.multiSelect.value = this.multiSelect.options.find(o => o.scopeId === this.brand.targetID) || { scopeId: this.brand.targetId, description: this.brand.targetDescription }
            }
            else {
                this.multiSelect.value = this.multiSelect.options.find(o => o.targetLayerId === this.getCurrentTargetLayerId)
                if (!this.multiSelect.value)
                    this.multiSelect.options.at(0)

                this.brand.targetID = this.multiSelect.value.scopeId
            }
            
            this.brandCopy = cloneDeep(this.brand);
            this.brandKey++;
        },
        
        onCancel() {
            this.$sidePanel.close();
            return;
        },

        async onSaveBrand() {
            let savedBrand = null;
            this.preventSave = true;

            try {
                savedBrand = await this.saveBrand(this.brand);
            } 
            catch (error) {
                prompts.error({
                    text: this.$i18n.t('Brand.Messages.SaveBrandError')
                })
            }

            this.preventSave = false;
            savedBrand.description = getDescription(this.brand.descriptions)
            savedBrand.targetDescription = savedBrand.targetDescription || this.multiSelect.value.description

            if (savedBrand) {
                this.$sidePanel.submit({ 'save': true, 'row': savedBrand });
            }
        },
        
        onScopeChanged(target) {
            this.brand.targetID = target ? target.scopeId : 0;
        }
    }
}
</script>