<template src="./main.html"></template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import agGridGetters from '@/core/components/common/grid/getters/getters.js';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import receivingManangement from '@/backoffice/modules/purchase/components/sidePanel/receivings/receiving-management/receiving-management.vue';
import creationButton from '@/core/components/common/creation-button/creation-button.vue';

import sliderList from '@/core/components/common/slider-list/slider-list.vue'
import information from '@/core/components/common/slider-list/templates/information/information.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import grid from  '@/core/components/common/grid/grid.vue'

export default {
  name: '',
  components: {
    sliderList,
    information,
    searchBar,
    grid,
    creationButton
  },
  data() {
    return {
      gridOptions: {
        headerHeight: 50,
        rowHeight: 50,
        defaultColDef: {
          resizable: true
        },
        columnDefs: [],
        rowSelection: 'single',
        overlayNoRowsTemplate: '<span>' + this.$t('Purchase.Messages.NoOrderData') + '</span>',
        onGridReady: () => {
          this.setColumnDefs();
          this.getAggregatedReceivings();
        },
        onGridSizeChanged: () => {
          if(this.isMobile){
            this.gridOptions.columnApi.autoSizeAllColumns(false);
          }
          else{
            this.gridOptions.api.sizeColumnsToFit();
          }
        }
      },
      filters: []
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.getAggregatedReceivings();
    },
    isMobile(){
      this.setColumnDefs();
    },
  },
  beforeDestroy(){
    this.resetAggregatedReceivings()
  },
  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
    ...mapGetters('Receiving', ['getAggregateReceivings']),

    canCreateReceiving() {
      return this.hasPermission(['RECEIVING_CUD'])
    },
    openReceivings(){
      if(!this.getAggregateReceivings || this.getAggregateReceivings.length == 0)
        return []

      return this.getAggregateReceivings?.filter(r => r.receivingStatus === ReceivingStatus.OPEN)
    }
  },

  methods: {
    ...mapActions('Receiving', ['fetchAggregateReceivings']),
    ...mapMutations('Receiving',{resetAggregatedReceivings: 'RESET_AGGREGATE_RECEIVINGS'}),

    async getAggregatedReceivings(){
      try{
        await this.fetchAggregateReceivings();
      }
      catch(error){
        const codeTranslate = error?.status===403?'Purchase.Messages.ForbiddenAction':'Purchase.Messages.GetReceivingsError'
        prompts.error({
          text: this.$t(codeTranslate)
        });
      }

      this.gridOptions.api.setRowData(this.getAggregateReceivings);

      this.$nextTick(() => {
        this.setDefaultFilters();
      });
    },

    setColumnDefs() {
      this.gridOptions.api.setColumnDefs([
        { 
          field: 'receivingNO', 
          sort: 'desc', 
          headerName: !this.isMobile ? this.$t('Purchase.Receiving.Columns.ReceivingNO') : '#',
          headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingNO'),
          cellRenderer: 'linkRenderer',
          cellRendererParams: (params) => {
            return {
              route: { name: 'receiving', params: { id: params.data.receivingID } }
            }
          },
        },
        { hide: false, field: 'vendorName', headerName: this.$t('Purchase.Receiving.Columns.VendorName'), headerTooltip: this.$t('Purchase.Receiving.Columns.VendorName'),
          valueGetter: params => this.vendorNameGetter(params.data)
        },
        { hide: false, field: 'nbOfBodies', headerName: this.$t('Purchase.Receiving.Columns.ReceivingBodySums'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingBodySums') },
        { hide: false, field: 'createdDate', valueGetter: agGridGetters.dateWithMonthNamed, headerName: this.$t('Purchase.Receiving.Columns.CreatedDate'), headerTooltip: this.$t('Purchase.Receiving.Columns.CreatedDate') },
        { hide: this.isMobile, field: 'userUsername', headerName: this.$t('Purchase.Receiving.Columns.UserUsername'), headerTooltip: this.$t('Purchase.Receiving.Columns.UserUsername') },
        { hide: this.isMobile, field: 'receivingStatus', filter: 'agTextColumnFilter', valueGetter: params => { return this.$t(`Purchase.Receiving.Status.${params.data.receivingStatus}`); }, headerName: this.$t('Purchase.Receiving.Columns.ReceivingStatus'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingStatus') }
      ]);
    },
    newReceiving(){
      this.$sidePanel.show([{ component: receivingManangement }], null, { backgroundIsDisabled: true });
    },
    filtersChanged(filters){
      this.filters = filters;
    },
    setDefaultFilters(){
      const instance = this.gridOptions.api.getFilterInstance('receivingStatus');
      const voidStatusTranslation = this.$t(`Purchase.Receiving.Status.${ReceivingStatus.VOID}`);
      instance.setModel({ type: 'notEqual', filter: voidStatusTranslation});
      this.gridOptions.api.onFilterChanged();
    },

    vendorNameGetter(receivingBody){
      if(receivingBody.nbOfVendors == 1)
        return receivingBody.firstVendor;
      else if(receivingBody.nbOfVendors > 1)
        return this.$t('Purchase.Receiving.Columns.MultipleVendors');
      else
        return this.$t('General.NotAvailable')
    }
  }
}
</script>