<template src="./receiving-management.html"></template>

<style lang="scss">
    @import './receiving-management.scss';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters, mapActions } from 'vuex';
import { OrderStatus, ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import searchList from '@/core/components/common/search-list/search-list.vue';
import multiselect from 'vue-multiselect';
import prompts from '@/core/tools/notifications/notifications';
import { toDate, toDateTime } from "@/core/functions/format-value.js"
import moment from 'moment';

const orderNoField = 'OrderNO';
const vendorNameField = 'VendorName';

export default {
    name: 'receiving-management',

    components: {
        searchList,
        multiselect
    },

    data() {
        return {

            receiving: {},
            orderList: [],
            searchFields: [
                {
                    field: orderNoField,
                    description: this.$t('Purchase.Order.Columns.OrderNO')
                },
                {
                    field: vendorNameField,
                    description: this.$t('Purchase.Order.Columns.VendorName')
                }
            ],
            selectedField: null,
            selectedOrders: [],
            preventSave: false
        };
    },
    computed: {
        ...mapGetters('Receiving', ['getReceiving', 'getRelatedReceivings']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'getUser']),
        hasSelectedOrders() {
            return this.selectedOrders.length > 0;
        }
    },

    mounted() {
        this.selectedField = this.searchFields[0];
        this.setReceiving();
    },

    methods: {
        ...mapActions('Order', ['fetchOrdersByVendorName', 'fetchOrdersByNo']),
        ...mapActions('Receiving', ['fetchRelatedReceivings', 'saveReceiving']),

        setReceiving() {
            this.receiving = {
                canUpdate: true,
                targetLayerID: this.getCurrentTargetLayerId,
                receivingStatus: ReceivingStatus.OPEN,
                createdDate: toDate(new Date()),
                createdDatetime: toDateTime(new Date()),
                userEmail: this.getUser.email,
                userFullName: `${this.getUser.firstName} ${this.getUser.lastName}`,
                userID: this.getUser._UserID,
                userUsername: this.getUser.username
            };
        },

        async scanOrder(orderNo){
            this.selectedOrders = [];

            if (!orderNo || orderNo.length == 0) {
                this.orderList = [];
                return;
            }

            const orders = await this.fetchOrderByFieldAndValue(orderNoField, orderNo);

            if(!orders){
                this.orderList = [];
                this.selectedOrders = [];
                return;
            }

            const filteredOrders = orders.filter(o => o.orderNO == orderNo && (o.orderStatus == OrderStatus.PENDING || o.orderStatus == OrderStatus.PARTIALLYRECEIVED))

            if(filteredOrders.length == 0){
                this.orderList = [];
                this.selectedOrders = [];
                return;
            }

            this.orderList = filteredOrders;

            await this.fetchReceivingBodiesFromOrders(this.orderList);

            this.selectedOrders = this.orderList;
        },

        async searchOrders(searchValues) {
            this.selectedOrders = [];

            if (!this.selectedField || !Array.isArray(searchValues) || searchValues.length == 0) {
                this.orderList = [];
                return;
            }

            const searchValue = searchValues[0]

            const orders = await this.fetchOrderByFieldAndValue(this.selectedField.field, searchValue);

            if(!orders)
                return;

            this.orderList = orders.filter(o => o.orderStatus == OrderStatus.PENDING || o.orderStatus == OrderStatus.PARTIALLYRECEIVED)
            this.fetchReceivingBodiesFromOrders(this.orderList);
        },

        async fetchOrderByFieldAndValue(field, searchValue){
            try {
                if(field == orderNoField)
                    return await this.fetchOrdersByNo(searchValue);
                else if(field == vendorNameField)
                    return await this.fetchOrdersByVendorName(searchValue);
            }
            catch(error) {
                prompts.error({
                    text: this.$t('Purchase.Messages.GetOrdersError')
                });
            }
        },

        onCancel() {
            this.$sidePanel.close();
        },

        async fetchReceivingBodiesFromOrders(orders){
            await this.fetchRelatedReceivings({receivingId: null, orderIds: orders.map(o => o.id)});
            this.setCalculatedQtyForOrders(this.orderList, this.getRelatedReceivings);
        },

        setCalculatedQtyForOrders(orders, receivingBodies){
            orders.forEach(order => {
                const filteredReceivingBodies = receivingBodies.filter(receivingBody => { return receivingBody.orderID == order.id })
                this.$set(order, 'totalReceivedQty', filteredReceivingBodies.reduce((previous, next) => ( previous + next.qty ), 0));
                this.$set(order, 'totalOrderedQty', order.orderBodies.reduce((previous, next) => ( previous + next.qty ), 0));
            });
        },

        async onSaveReceiving() {
            const receivingBodies = this.getReceivingBodies();

            const receiving = {
                ...this.receiving,
                receivingBodies: receivingBodies
            };

            this.preventSave = true;

            try {
                await this.saveReceiving(receiving);
            } catch (error) {
                const codeTranslate = error?.status===403?'Purchase.Messages.ForbiddenAction':'Purchase.Messages.SaveReceivingError'
                prompts.error({
                    text: this.$t(codeTranslate)
                });
            }

            this.preventSave = false;

            if (this.getReceiving.id) {
                this.$router.push({ name: 'receiving', params: { id: this.getReceiving.id } });
            }
        },

        getReceivingBodies() {
            return this.selectedOrders
                .reduce((arr, order) => {
                    const bodies = order.orderBodies
                        .filter(body => !body.isDeleted)
                        .map(body => {
                            return {
                                id: null,
                                receivingID: null,
                                orderID: body.orderID,
                                orderBodyID: body.id,
                                createdOn: moment(),
                                vendorID: order.vendorID,
                                vendorCode: order.vendorCode,
                                vendorName: order.vendorName,
                                purchaseBodyInfoID: body.purchaseBodyInfoID,
                                qty: 0,
                                unitQty: body.unitQty,
                                unitCost: body.unitCost,
                                cost: 0.00
                            };
                        });

                    return [ ...arr, ...bodies];
                }, []);
        },

        selectOrderField(field) {
            this.selectedField = field;
            this.selectedOrders = [];
        },

        selectionChanged(selectedOrders) {
            this.selectedOrders = selectedOrders;
        }
    }
}
</script>