<template src='./promotion-product-scan.html'></template>

<script>
import { mapGetters } from 'vuex';
import promotionProductAdd from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-add/promotion-product-add.vue';
import promotionProductSearch from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-search/promotion-product-search.vue';
import ScanProduct from '@/core/components/layout/side-panel/scan-product/scan-product.vue'

export default {
  name: 'promotion-product-scan',

  components: {
    ScanProduct
  },

  computed: {
    ...mapGetters('Promotion', ['getPromotion', 'getPromotionDescription'])
  },
  methods: {
    cancel() {
      this.$sidePanel.close();
    },

    search() {
      this.$sidePanel.show([{ component: promotionProductSearch }], null, { classes: ['side-panel--sticky-footer'] });
    },

    select(product) {
      this.$sidePanel.show([{ component: promotionProductAdd }], { productUpc: product.itemUPC });
    }
  }
}
</script>
